
import React, {useEffect} from 'react';
import project from '../assests/img/project-home-button.jpg'
import one from '../assests/1-chip.png'
import two from '../assests/2-ducs.png'
import three from '../assests/3-cat.png'
import { Link } from "react-router-dom";






const Home = () => {

    return(
    <>
      <div className='col-12 mb-3' id="top">
      <div className='text-end'><p className=' btn btn-primary bth my-3 w-25 rounded-0 d-none d-md-inline-block'>Home</p></div>
      <div className='mt-3 mt-md-0'></div>
        <div className='hero'>
           
           <div className='hero-text'>
           <h4>Captures the Experience</h4>
           <h4>Builds the Designs</h4>
           <h4>Records the Journey</h4>
           <p className='text-white m-2 pt-1 bread-shadow'>Jason Ure | <span className='d-none d-md-inline'>User Experience Designer</span><span className='d-inline d-md-none'>UX Designer</span></p>
           </div>
         </div>
         <div className='row'>
         <div className='col-12'>
          <div className='row'>
          <div className='adjustment col-md-8 col-12'>

          <div className="card bg-dark text-white rounded-0">
          <img className="card-img rounded-0" src={project} alt="design"/>
          <div className="card-img-overlay rounded-0">
            <h4 className="card-title btn bg-black text-white p-2 btnDesign"><Link className='nav-link' target='_top' to="/Projects">See Projects</Link></h4>
            
          </div>
        </div> 
         </div>
         <div className='col-md-4 col-12'>
           <h4 className='pt-2 '>Qualifications</h4>
           <p>My emphasis and specialty is in User Experience Design and Full Stack Development. My focus has been mostly on the front-end development with User Experience testing and Design. I received a certification in User Experience Design from University. I have also completed all corse work for bachelor and associate degree in Web and User Experience. I am a trained professional.
           </p>
         </div>

         </div>
         </div>
         <div className='col-12 col-lg-4 col-md-6 col-lg-8 text-center mt-4'>

         </div>
         </div>
         <div class="jumbotron jumbotron-fluid py-4">
          <div class="container py-5 my-5">
            <h1 class="display-4 text-center">Highlights</h1>
            <p class="lead text-center">Projects Highlights Below</p>
          </div>
        </div>
        <div className='row m-0'>
          <div className='col-md-6 col-sm-6 col-lg-4 col-12 ez rounded-0'>
          <div class="jumbotron jumbotron-fluid">
            <div class="container">
              <h4 class="display-4">App with the Chipest!</h4>
              <img src={one} alt="pig" height={"520px"} width={"520px"} className="img-fluid"></img>
              <p class="lead">Come along for the ride in this amazing banking application that me and
               some of my colleagues made. We created, planned, and built a full stack application.<hr/> 
               After the app was done the little piggie said, "Aint no wolf blowing
               <Link to="/projects/chippin" target='_top' ><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>
               
                {/* this app down!".
               The Chippin App was completed working front to back. Working with multiple frameworks,
                designs, content, research, and a tailored deployment */}
                </p>
            </div>
          </div>
          </div>
          <div className='col-md-6 col-lg-4 col-sm-6 col-12 ez rounded-0'>
          <div class="jumbotron jumbotron-fluid">
          <div class="container">
            <h4 class="display-4">Critter App!</h4>
            <img src={three} alt="kitty" height={"520px"} width={"520px"} className="img-fluid"></img>
            <p class="lead">Braxton a 3 year old Short Hair cat is a shy little fella and is good with
             his new family. Come along as I have researched, designed, and studied for an app designed 
             for new cat owners and as well for cat care in any phase. This App can bring peace of mind 
             for cat owners as <Link to="/projects/catmatcher" target='_top'><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>

              </p>
             
             {/* well as an easy way to match to the right cat.<hr/>This project consist 
             of the User Experience Design Process</p> */}
          </div>
</div>
          </div>
          <div className='col-md-6 col-lg-4 col-sm-6 col-12 rounded-0'>
          <div class="jumbotron jumbotron-fluid">
            <div class="container">
              <h4 class="display-4">For Duck Sake! App! </h4>
              <img src={two} alt="duck" height={"520px"} width={"520px"} className="img-fluid"></img>
              <p class="lead">501 non profit? No problem! Duck Sake application was a redesign of a duck 
              charity organization. This re-design was completed with 2 other colleagues of mine were we 
              collabrorated throughout the User Experience and Design process rebuilding this application. 
              <Link to="/projects/forducksake!" target='_top'><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>
              </p>
            </div>

          </div>
          </div>
        </div>
         </div>
       
    </>)
  };
  
  export default Home;