import pro from '../assests/img/1683944332866.png'
import tik from '../assests/img/tik.mp4'
import large from '../assests/img/large-about.png'
import small from '../assests/img/small-about.png'
import React, { useState } from 'react'

const About = () => {
    let [toggle, setToggle] = useState(false)
    let [toggle2, setToggle2] = useState(false)
    let [toggle3, setToggle3] = useState(false)
    let [toggle4, setToggle4] = useState(false)
    let [toggle5, setToggle5] = useState(false)
    return(
    <>
          <div className="col-12 mb-3">
          <div className='text-end'><p className=' btn btn-primary bth my-3 w-25 rounded-0 d-none d-md-inline-block'>About</p></div>
          <div className='mt-3 mt-md-0'></div>
          <div className='heroA'>
           
           <div className='hero-text'>
           <h4>Enjoys the learnings</h4>
           <h4>Communicates the necessary</h4>
           <h4>Gets the media</h4>
           <p className='text-pdrr pdrr1 text-md-white m-2 pt-1 bread-shadow1' >About | <span className='d-none d-md-inline'>User Experience Designer</span><span className='d-inline d-md-none'>UX Designer</span></p>
           </div>
         </div>
          <div className='row justify-content-center mx-md-0'>
          <div className='d-md-block d-none mybg-about p-5 '><img src={large} className='aboutE  mx-auto col-md-12 col-lg-8 mt-3 d-md-block d-none'/>
          </div>
          <img src={small} className='pt-2 mt-1 col-12 d-md-none d-lg-none'/>

          {/* <div className='col-md-8 col-12  mt-md-5 pt-md-5 text-center  '>
          <div className="btn btn-primary mt-4 mt-md-0 mt1 mt-md-5">
            Web and User Experience Design <span className="badge bg-secondary">7 years</span>
          </div>
          
          <div className="btn btn-primary mt2">
            Digital Media Experience <span className="badge bg-secondary">12 years</span>
          </div>
          <div className="btn btn-primary mt3">
            Communications and Leadership Training <span className="badge bg-secondary">7 years</span>
          </div>
          
          </div>
          <div className='justify-content-end justify-content-md-center row mt-md-5 pl-5'><div className='col-4'><img className='aboutimg1 rounded-0 mt-5 mt-md-0 mtp' src={pro} /></div></div>
           */}
          </div>
          
          <div className="row">
          
          <div className='col-md-8  col-12'>
            
            <div className="text-dark mt-3 p-2 mystyle col-12 text-start mx-auto col-lg-12">
            
            <h5 className='btn bg-white border border-3 border-primary text-primary p-1 m-1'>Overview of skills</h5>
            <br></br>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Reasearch</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Writing and Conducting Surveys</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Writing and Conducting Scritps</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Journy Mapping</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Design Sketching</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Design Prototypes</span>
            <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">Documenting</span>
                <hr/>
                <span className="badge rounded-pill border border-1 border-doge text-doge bg-white p-2 m-1">HTML5</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">CSS3 - FLEX/GRID/CUSTOM LAYOUT</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">SASS</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">SASS/BOOTSTRAP</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">BOOTSTRAP</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">BULMA</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">SKELETON</span>
                <span className="badge rounded-pill bg-doge text-white p-2 m-1">CSS3</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">JAVA</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">JS</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">VUEJS</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">VUE CLIENT</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">PYTHON</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">PHP</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">WP</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">LAMP STACK(SUM 2022)</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">REACT</span>
                <span className="badge rounded-pill bg-primary text-white p-2 m-1">REACT STACK/EXPRESS/RESTAPI</span>
                <span className="badge rounded-pill bg-secondary text-white p-2 m-1">RELATIONAL DBS</span>
                <span className="badge rounded-pill bg-secondary text-white p-2 m-1">MYSEQUAL DBS</span>
                <span className="badge rounded-pill bg-secondary text-white p-2 m-1">NOSEQUAL DBS</span>
                <span className="badge rounded-pill bg-secondary text-white p-2 m-1">INC MONGO DB</span>
                <span className="badge rounded-pill bg-secondary text-white p-2 m-1">INC FIREBASE DB</span>
                <hr/>
                <span className="badge rounded-pill bg-green text-white p-2 m-1">PLAIN TEXT EDITOR</span>
                <span className="badge rounded-pill bg-green text-white p-2 m-1">VS CODE</span>
                <span className="badge rounded-pill bg-green text-white p-2 m-1">ADOBE DREAMWEAVER</span>
                <span className="badge rounded-pill bg-green text-white p-2 m-1">BRACKETS</span>
                <span className="badge rounded-pill bg-green text-white p-2 m-1">ATOMS</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">GIT HUB</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">NETLIFY</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">HEROKU</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">COMMAND LINE</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">CODE PEN</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">AUDOBE XD</span>
                <span className="badge rounded-pill bg-secondary text-accent p-2 m-1">ADOBE PHOTOSHOP <span className="badge bg-secondary">11 yrs</span>
                </span><span className="badge rounded-pill bg-secondary text-accent p-2 m-1">ADOBE ILLUSTRATOR<span className="badge bg-secondary">11 yrs</span>
                </span><span className="badge rounded-pill bg-secondary text-accent p-2 m-1">ADOBE PREMIER PRO<span className="badge bg-secondary">11 yrs</span>
                </span><span className="badge rounded-pill bg-secondary text-accent p-2 m-1">MICROSOFT OFFICE SUITES<span className="badge bg-secondary">11 yrs</span></span></div>
                <div className='mt-3'>
              <h4>FAQ</h4>
              <div className="accordion accordion-flush" id="accordionFlushExample">
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button bg-doge text-baby" onClick={() => setToggle(!toggle)} type="button">
        What is your User Experience Design Process?
      </button>
    </h2>
    {toggle && (
    <div id="flush-collapseOne" className="accordion-collapse" aria-controls="flush-collapseTwo">
      <div className="accordion-body">
        <p>Steps include</p>
        <ol>
          <li>Plan</li>
          <li>Reasearch</li>
          <li>Design</li>
          <li>Test</li>
          <li>Implement</li>
        </ol>
      </div>
    </div>
    )}
  </div>
    
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button bg-doge text-baby" onClick={() => setToggle2(!toggle2)} type="button">
        What is the difference between User Experience(UX) and User Interface(UI)?
      </button>
    </h2>
    {toggle2 && (
    <div>
      <div className="accordion-body">
        <p>User Experience is how ones see's, think's, and hear's at any given moment.</p>
        <p>User Interface is the communication technology that alows a user to interact.</p>
        <p>User Experience + User Interface = User Experience Design</p>
        </div>
    </div>
    )}
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button bg-doge text-baby" onClick={() => setToggle3(!toggle3)} type="button">
        What is your code building process?
      </button>
    </h2>
    {toggle3 && (
    <div>
      <div className="accordion-body">
        <p>Its very much the same as my UXD process except it is geared toward the coding the User Interface with the design that has been brought down.</p></div>
    </div>
    )}
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button bg-doge text-baby" onClick={() => setToggle4(!toggle4)} type="button">
        Do you design and code independently? 
      </button>
    </h2>
    {toggle4 && (
    <div>
      <div className="accordion-body">
        <p>Yes! You can use the form or reach out to me on social media.</p></div>
    </div>
    )}
  </div>
  <div className="accordion-item">
    <h2 className="accordion-header">
      <button className="accordion-button bg-doge text-baby" onClick={() => setToggle5(!toggle5)} type="button">
        Do you respond to negitive feedback? How do you handle that?
      </button>
    </h2>
    {toggle5 && (
    <div>
      <div className="accordion-body">
        <p>Feedback is always good! It is a key during the testing process no matter if its good or bad. A bad negitive response during
           feedback always has constructive reasoning. If any negitive feedback does not give constructive stucture, I will always ask questions getting to better understand why and make changes so the application can be better.</p></div>
    </div>
    )}
  </div>
</div>
            </div>
          </div>
          <div className='col-md-4 mt-3 col-12'>
            {/* <h4 className='pb-1 pt-2'>Quick run down of skills</h4> */}
          <video className='aboutimg' controls>
            <source src={tik} type="video/mp4"/>
            Your browser does not support the video tag.
            </video>
          </div>
          </div>
          

          </div>
    </>)
  };
  
  export default About;