import React from "react";
import {Helmet} from "react-helmet";

import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./App";
import Home from "./pages/Home";
import Project from "./pages/Projects";
import About from "./pages/About";
import NoPage from "./pages/404";

import ProjectChip from "./pages/project-details/chippen"
import ProjectCat from "./pages/project-details/cat"
import ProjectDuck from "./pages/project-details/ducksake"

import reportWebVitals from "./reportWebVitals";

import ScrollToTop from './top'
export default function App() {
  return (
    
    <BrowserRouter>
    
     <Helmet>
                <meta charSet="utf-8" />
                <title>Jason Ure</title>
                <author>Jason Ure</author>
                <link rel="icon" href="/favicon.png"/>
            </Helmet>
      
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route className="easy" index element={<Home />} />
          <Route className="easy" path="about" element={<About />} />
          <Route className="easy" path="projects" element={<Project />} />
          <Route className="easy" path="projects/chippin" element={<ProjectChip />} />
          <Route className="easy" path="projects/catMatcher" element={<ProjectCat />} />
          <Route className="easy" path="projects/forDuckSake!" element={<ProjectDuck />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
      <ScrollToTop/>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);
