
import cat from '../../assests/cat-meow.png'
import catquiz from '../../assests/cat/adorable-kittens-with-fuzzy-hair-sitting-white-surface-with-two-guinea-pigs.jpg'
import catquiz1 from '../../assests/cat-app/cat-quiz.png'
import insurance from '../../assests/cat-app/insurance.png'
import profile from '../../assests/cat-app/profile.png'
import simple from '../../assests/cat-app/simple cat.png'
import tut from '../../assests/cat-app/tut.png'

const ProjectCat = () => {
    return(
        <>
        <div className='row'>
        <div className='bggB d-none d-xl-flex col-xl-2'>
           
           </div>

        <div className="col-12 col-md-9 col-xl-8 overDiv pb-3">
        <div className='row'><div className='col-10 col-md-8'><div className='card rounded-0 p-2 mt-3'>
            <div className=''>
                <h4>Information</h4>
                <p className='p-0 m-0'>Author: Jason Ure</p>
                <p className='p-0 m-0'>Showcasing: User Experience Design Project</p>
                <p className='p-0 m-0'>Time: Months</p>
                <p className='p-0 m-0'>Contributers: Self only</p>
            </div>
            </div></div><div className='col-md col-2 w-50'><p className='rotate btn btn-primary bth my-3 w-100 rounded-0 d-md-inline-block'>Cat Matcher</p></div></div>
        <div className='mt-3 mt-md-0'></div>
        <h3 className="display-3 pt-1">CatMatcher</h3>
        <p class="lead l">
        Braxton a 3 year old Short Hair cat is a shy little fella and is good with his new family. Come along as I have researched, designed, and studied for an app designed for new cat owners and as well for cat care in any phase. This App can bring peace of mind for cat owners as well as an easy way to match to the right cat.<hr/>This project consist 
             of the User Experience Design Process
        </p>
        <h3 className="display-3">Overview</h3>
        <img src={catquiz} className='img-fluid' alt="cats" />
        <div className="row">
            <div className="col-12 col-md-6">
            <p className="lead 1">
            Including
        <ul>
            <li >Reasearch</li>
            <li>Journey Mapping</li>
            <li>Scripting Interviews</li>
            <li>Conducting Interviews</li>
            <li>Design Sketches/Prototypes</li>
            <li>Qualitative and Quantitative Measurements</li>
        </ul>
        
        </p>
        <p>
        <small>
            *The finished design I added more modern design by adding more friendly and appealing background. 
            <i>(most testing was done between wireframe phases were there was less graphical detail shown in the XD example.)</i>
        </small>
        </p>
            </div>
            <div className="col-12 col-md-6"><img src={cat} className='img-fluid'/></div>
        </div>
       
        <hr/>
        <h3>Project Scope</h3>
        <p>
            This project focused on modern usuability. This included writing instruction for users on this new application concept, testing multiple ways of finding and matching cats, and competitor analysis.  
        </p>
        <h4>Research</h4>
        <p>I started this project by doing alot of research on cat finders or matchers. There were a few that seem pretty intresting incuding one having a cat quiz and the others that had a library with fun cat cards. I finally decied that I would pick a few of these sites to do some usablitiy testing. From this analysis I found some really good things I wanted to test and impliment into this project
            as well as things I was going to avoid. 
        </p>

        <p className='pb-3'> 
            On the other test I used for more of the search sites I found that it took the users a bit of time trying to navigate the website tring to read through all the descriptions. However on one of the websites the set of filters much helped the performance of getting to a type of cat one wanted. 
        </p>
        <div id='scroll'><img for="scroll" alt="cat-quiz-app-pages" src={catquiz1} /></div>
        <h4 className='pt-3 mt-3'>The Problem</h4>
        <p>
        I next address the rest of the design of the cat quiz. The cat quiz site that I tested on actually had good visuals, but the issue was that people had a hard time how to answering questions as well as moving to the next question. The quiz did not use standard way of moving through quizzes like many other sites. So, from here I created a modern friendly design for a cat quiz app and avoided any form of confusing user interface graphics and went with a friendly more common approach.
        </p>
        <div className='text-center py-3 mb-3 mt-3'><img src={simple} className='img-fluid' /></div>
        <h4 className='py-3'>The Conclusion</h4>
        <p>
        By the time I was getting closer a having a cat matching app with a good interface as well as a user experience I was dazzled by the results. It would seem to me that I have created a new experience for the user of the next generation. The issue with this is that people may not understand how to navigate that app so I added some learning design tutorials to the application so the user will know how to navigate the app.
        </p>
     
       
        <div className='col-12 text-center mx-auto mt-3'><img src={tut} className=' mx-auto' /></div>
  
        <p className="lead l py-5">This prototype is brought over by adobe XD cloud and was used for some of the final click through testing.</p>
        <p className="d-block d-sm-none bg-accent p-4">Oops! It looks like you are viewing Adobe XD prototype in too small of a window! Please veiw on a bigger window and try again!</p>
        <iframe className="shadower d-none d-sm-block mx-auto" width="428" height="713" src="https://xd.adobe.com/embed/c67318dd-b75f-4074-832b-4a9eef46ea58-9320/" frameborder="0" allowfullscreen></iframe>
        </div>
        <div className='bggA d-none d-md-block col-12 col-xl-2 col-md-3'>
           
        </div>
        </div>
        </>
    )
}

export default ProjectCat