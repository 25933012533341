const ProjectDuck = () => {
    return(
        <>
        <div className='row'>
        <div className='bggB d-none d-xl-flex col-xl-2'>
           
           </div>

        <div className="col-12 col-md-9 col-xl-8 overDiv pb-3">
 
        <div className='row'><div className='col-10 col-md-8 '><div className='card rounded-0 p-2 mt-3'>
            <div className=''>
                <h4>Information</h4>
                <p className='p-0 m-0'>Author: Jason Ure</p>
                <p className='p-0 m-0'>Showcasing: User Experience Design Project</p>
                <p className='p-0 m-0'>Time: Months</p>
                <p className='p-0 m-0'>Contributers: Jason U., Niki G., Travis H.  </p>
            </div>
            </div></div><div className='col-md col-2 w-50'><p className='rotate btn btn-primary bth my-3 w-100 rounded-0 d-md-inline-block'>For Duck Sake!</p></div></div>
        <div className='mt-3 mt-md-0'></div>
        <h3 className="display-3">For Duck Sake!</h3>
        <p class="lead l">
        501 non profit? No problem! Duck Sake application was a redesign of a duck 
              charity organization. This re-design was completed with 2 other colleagues of mine were we 
              collabrorated throughout the User Experience and Design process rebuilding this application. 
        </p>
        <h3 className="display-3">Overview</h3>
        <p className="lead 1">
            Including
        
        <ul>
            <li >Reasearch</li>
            <li>Journey Mapping</li>
            <li>Scripting Interviews</li>
            <li>Conducting Interviews</li>
            <li>Design Sketches/Prototypes</li>
            <li>Qualitative and Quantitative Measurements</li>
        </ul>

        </p>
        <hr/>
        <p className="lead l">You may view a final prototype below</p>
        <p className="lead l">This prototype is brought over by adobe XD cloud and was used for some of the final click through testing.</p>
        <p className="d-block d-sm-none bg-accent p-4">Oops! It looks like you are viewing Adobe XD prototype in too small of a window! Please veiw on a bigger window and try again!</p>
        <iframe className="d-none d-sm-block shadower" width="428" height="920" src="https://xd.adobe.com/embed/4ab8bed9-46f1-4d1c-b96c-3adef5eb46ad-1feb/" frameborder="0" allowfullscreen></iframe>
        </div>
        <div className='bggA d-none d-md-block col-12 col-xl-2 col-md-3'>
           
        </div>
        </div>

        
        </>
        
    )
}

export default ProjectDuck