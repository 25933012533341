import React, {useState, useEffect} from 'react';
import './App.scss';
import home from './assests/png/001-home.png'
import about from './assests/png/002-team.png'
import projects from './assests/png/003-blueprint.png'
import contact from './assests/png/004-chat.png'
import linkedIn from './assests/png/005-linkedin.png'
import social from './assests/png/006-social-media.png'
import mail from './assests/png/007-mail-send.png'
import forward from './assests/ff.png'
import res from './assests/img/mydownaward/png/001-resume.png'

import profile from './assests/img/form-icon/png/002-user.png'
import business from './assests/img/form-icon/png/003-enterprise.png'
import phone from './assests/img/form-icon/png/004-smartphone.png'
import arrow from './assests/img/form-icon/png/001-right-arrow.png'
import purp from './assests/img/form-icon/png/005-purpose.png'

import project from './assests/img/project-home-button.jpg'
import { Outlet, Link } from "react-router-dom";
import emailjs from "emailjs-com";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SERVICE_ID = "service_itsure";
const TEMPLATE_ID = "template_ulxe23j";
const USER_ID = "user_sGUBcArMMaUDrdlWP2hQs";

// toast.info('itsure.io 2.0 beta! App should be good to go! Will be adding more details and bug fixes if needed in weeks to come! Thanks for your intrest!', {
//   position: toast.POSITION.BOTTOM_RIGHT })
  // toast.info('feedback is well accepted, any is welcome!', {
  //   position: toast.POSITION.BOTTOM_RIGHT })

const handleOnSubmit = (e) => {
  e.preventDefault();
  const respond = emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, e.target, USER_ID) ? 
        toast.success('Message Sent!', {
            position: toast.POSITION.TOP_RIGHT
    }) : toast.warning('Try Agin! If problem persist Email (2jason.ure@gmail.com) !', {
      position: toast.POSITION.TOP_Right })
  e.target.reset()
};

const myMail = () => {
  toast.info('The contact form is the quickest way to email me. I you would prefer to do it your self send your email to: 2jason.ure@gmail.com', {
    position: toast.POSITION.TOP_RIGHT })
}
function App() {

  
  
  const [form, setForm] = useState(false)
  let val = () => {
    if(form == false){
      setForm(true)
    }
    if(form == true){
      setForm(false)
    }
  }
  


  return (
    <>
      <div className='container-fluid bg-dark zzz'>
        <div className='row'>
          <div className='col-1 p-0'>
            
            <div className='col-1s navPad '>
            {/* <div className='arrow'>
            <a className=' lin'><img className='bshadow border border-white p-1 rotate bg-white rounded-circle' src={forward} width={'20px'} height={'20px'} />
                </a>
            </div> */}
            <div>
              <div className='text-white'>
              
                <p className='lin1 text-center pt-3'><Link to="/" target='_top'  className='text-white lin'><img className='border border-white p-1' src={home} width={'42px'} height={'42px'} />
                <br/> <span className='nav-text'>Home</span>
                </Link></p>
                <p className='lin1 text-center'><Link to="/About" target='_top' className='text-white lin'>
                <img className='border border-white p-1' src={about} width={'42px'} height={'42px'} />
                <br/>
                <span className='nav-text'>About</span></Link></p>
                <p className='lin1 text-center'><Link to="/Projects" target='_top' className='text-white lin'>
                <img className='border border-white p-1' src={projects} width={'42px'} height={'42px'} />
                <br/>
                <span className='nav-text text-center'>Projects</span></Link></p>
                <p className='horz-n lin1 text-center d-none d-sm-block d-md-block d-lg-block d-xl-none'><a type='button' onClick={val} className='text-white lin'>
                <img className='border border-white p-1' src={contact} width={'42px'} height={'42px'} />
                <br/>
                  <span className='nav-text'>Contact</span></a></p>
                <p className='horz-n cm lin1 text-center d-xs-block d-sm-none d-md-none d-lg-none d-xl-block'><a type='button' className='text-white lin'>
                <img className='border border-white p-1 bg-primaryDark cm' src={contact} width={'42px'} height={'42px'} />
                <br/>
                  <span className='nav-text'>Contact</span></a></p>
              </div>
              <p className='horz-n lin1 text-center'><a href='https://www.linkedin.com/in/jason-ure-a04309101' className='text-white lin'><img className='border border-white p-1 bg-white' src={linkedIn} width={'42px'} height={'42px'} />
                <br/> <span className='nav-text'>LinkedIn</span>
                </a></p>
                {/* nvv, */}
                <hr className='horz-n text-white d-none d-xs-block' />
                <p className='horz-n lin1 text-center'><a href='https://acrobat.adobe.com/link/review?uri=urn%3Aaaid%3Ascds%3AUS%3Ac1e1bada-4761-4bb2-8468-c6e9a0502c74' className='text-white lin'>
                <img className='border border-white p-1' src={res} width={'42px'} height={'42px'} />
                <br />
                  <span className='nav-text'>Resume</span></a></p>
            
            </div>
            
          </div>
          <div className='col col-11s'>
            <div className='row'>
            <div className='col-12 col-xl-8 pd'>
            
            <Outlet/>
            <form className='p-2 d-block d-xs-block d-sm-none d-md-none d-lg-none d-xl-none'  onSubmit={handleOnSubmit}>
              <h4>Request Form</h4>
              <div class="input-group mb-2">
              <div class="input-group-prepend">
                <lable class="input-group-text rounded-0"  ><span  className='form-icon'>@</span></lable>
              </div>
              <input type="email" class="form-control" id="reply_to" name="reply_to" placeholder="Email e.g., john123@gmail.com"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={profile} /></div>
              </div>
              <input type="text" class="form-control border-left-0" id="to_name" name="to_name" placeholder="Name e.g., John Miller"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={business} /></div>
              </div>
              <input type="text" class="form-control" id="from_business" name="from_business" placeholder="Title/Busness e.g., Director of..."/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={phone} /></div>
              </div>
              <input type="text" class="form-control" id="asdfnumber" name="from_number" placeholder="Phone e.g., 000-000-0000"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={purp} /></div>
              </div>
              <input type="text" class="form-control" id="subject" name="subject" placeholder="Subject e.g., Information"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={arrow} /></div>
              </div>
              <textarea type="text" rows="4" class="form-control"  id="message" name="message" placeholder="message e.g., I was wondering if..."></textarea>
            </div>
          

        <button type='submit' className='btn btn-primary px-3' style={{marginLeft:59 + "px"}}>Submit</button>
              </form>
            <div className='footer-tag text-center py-4 mt-0'>itsure.io | Jason Ure | &copy;2016-{(new Date().getFullYear())}</div>
            
            {/* <div className='bannerH'></div>
            <div className='banner'>
              this is the thingy
            </div> */}

            </div>
            <div className=' col d-xl-block d-lg-none d-md-none d-sm-none d-xs-none d-none bg-alice'>
            <div className='formS'>
              <form className='rels1 rel p-5' onSubmit={handleOnSubmit}>
              <h4>Request Form</h4>
              <div class="input-group mb-2">
              <div class="input-group-prepend">
                <lable class="input-group-text rounded-0"  ><span  className='form-icon'>@</span></lable>
              </div>
              <input type="email" class="form-control" id="reply_to" name="reply_to" placeholder="Email e.g., john123@gmail.com"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={profile} /></div>
              </div>
              <input type="text" class="form-control border-left-0" id="to_name" name="to_name" placeholder="Name e.g., John Miller"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={business} /></div>
              </div>
              <input type="text" class="form-control" id="from_business" name="from_business" placeholder="Title/Busness e.g., Director of..."/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={phone} /></div>
              </div>
              <input type="text" class="form-control" id="asdfnumber" name="from_number" placeholder="Phone e.g., 000-000-0000"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={purp} /></div>
              </div>
              <input type="text" class="form-control" id="subject" name="subject" placeholder="Subject e.g., Information"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={arrow} /></div>
              </div>
              <textarea type="text" rows="4" class="form-control"  id="message" name="message" placeholder="message e.g., I was wondering if..."></textarea>
            </div>
          

        <button type='submit' className='btn btn-primary px-3' style={{marginLeft:59 + "px"}}>Submit</button>
              </form>
            </div>
            </div>
            </div>
        </div>
        
      </div>
      </div>
      </div>
      {form ?
      <div className='my-mod'>
      <form className='p-5' onSubmit={handleOnSubmit}>
              <h4>Request Form</h4>
              <div class="input-group mb-2">
              <div class="input-group-prepend">
                <lable class="input-group-text rounded-0"  ><span  className='form-icon'>@</span></lable>
              </div>
              <input type="email" class="form-control" id="reply_to" name="reply_to" placeholder="Email e.g., john123@gmail.com"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={profile} /></div>
              </div>
              <input type="text" class="form-control border-left-0" id="to_name" name="to_name" placeholder="Name e.g., John Miller"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={business} /></div>
              </div>
              <input type="text" class="form-control" id="from_business" name="from_business" placeholder="Title/Busness e.g., Director of..."/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={phone} /></div>
              </div>
              <input type="text" class="form-control" id="asdfnumber" name="from_number" placeholder="Phone e.g., 000-000-0000"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon p-1' src={purp} /></div>
              </div>
              <input type="text" class="form-control" id="subject" name="subject" placeholder="Subject e.g., Information"/>
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text rounded-0"><img className='form-icon' src={arrow} /></div>
              </div>
              <textarea type="text" rows="4" class="form-control"  id="message" name="message" placeholder="message e.g., I was wondering if..."></textarea>
            </div>
          

        <button type='submit' className='btn btn-primary px-3' style={{marginLeft:59 + "px"}}>Submit</button>
              </form>
      </div> : null}
      <ToastContainer/>
    </>
  );
}

export default App;
