import one from '../assests/1-chip.png'
import two from '../assests/2-ducs.png'
import three from '../assests/3-cat.png'

import four from '../assests/kweso-workshop/kweso1.jpg'
import five from '../assests/kweso-workshop/ever1.gif'

import thingthree from '../assests/png3.png'

import myarr from '../assests/myarr.png'

import { Link } from "react-router-dom";

const Projects = () => {

    return (
        <>
            <div className="col-12">
            <div className='text-end'><p className=' btn btn-primary bth my-3 w-25 rounded-0 d-none d-md-inline-block'>Projects</p></div>
            <div className='mt-3 mt-md-0'></div>

            <div className='heroP'>
           
           <div className='hero-text'>
           <h4>Projects of Friendlyness</h4>
           <h4>Projects with Joy</h4>
           <h4>Projects that do Mercy</h4>
           
           <p className='text-white m-2 pt-1 bread-shadow3'>Projects | <span className="bg-white text-black rounded-1 border border-dark border-1 px-1"><bold>Products</bold></span> of <span className='d-none d-md-inline'>User Experience Design</span><span className='d-inline d-md-none'>UXD</span></p>
           </div>
         </div>
         <div class="jumbotron jumbotron-fluid py-4">
          <div class="container py-5 my-5">
            <h1 class="display-4 text-center">Project Reviews</h1>
            <p class="lead text-center">Detailed reviews of projects</p>
          </div>
        </div>
        <div className='row m-0'>
          <div className='col-md-6 col-sm-6 col-lg-4 col-12 ez1 rounded-0 mb-4'>
          <div class="jumbotron jumbotron-fluid shadower">
            <div class="container">
              <h4 class="display-4">App with the Chipest!</h4>
              <img src={one} alt="pig" height={"520px"} width={"520px"} className="img-fluid"></img>
              <p class="lead text-center">
                <Link to="/projects/chippin" target='_top'><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>
               
                {/* this app down!".
               The Chippin App was completed working front to back. Working with multiple frameworks,
                designs, content, research, and a tailored deployment */}
                </p>
            </div>
          </div>
          </div>
          <div className='col-md-6 col-lg-4 col-sm-6 col-12 ez1 rounded-0 mb-4'>
          <div class="jumbotron jumbotron-fluid shadower">
          <div class="container">
            <h4 class="display-4">Critter App!</h4>
            <img src={three} alt="kitty" height={"520px"} width={"520px"} className="img-fluid"></img>
            <p class="lead text-center"><Link to="/projects/catMatcher" target='_top'><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>

              </p>
              
             
             {/* well as an easy way to match to the right cat.<hr/>This project consist 
             of the User Experience Design Process</p> */}
          </div>
</div>
          </div>
          <div className='col-md-6 col-lg-4 col-sm-6 col-12 rounded-0'>
         
          <div class="jumbotron jumbotron-fluid shadower">
            <div class="container">
              <h4 class="display-4">For Duck Sake! App! </h4>
              <img src={two} alt="duck" height={"520px"} width={"520px"} className="img-fluid"></img>
              <p class="lead text-center">
              <Link to="/projects/forDuckSake!" target='_top'><button className='btn bg-white border-black border text-black-50 btn-sm p-0 px-1 mx-1' style={{padding: 10 + "px"}}>...View &#x27F6;</button></Link>

              </p>
       
             </div>
          </div>
          </div>
        </div>
        <div class="jumbotron jumbotron-fluid py-4">
          <div class="container py-5 my-5">
            <h1 class="display-4 text-center">Websites</h1>
            <p class="lead text-center">Projects designed and created on the front-end</p>
          </div>
        </div>

                {/* <div className="row">
                <iframe scrolling="no" width="428" height="713" src="http://chipin.business/" frameborder="0" allowfullscreen></iframe>
            <iframe width="428" height="713" src="https://xd.adobe.com/embed/4ab8bed9-46f1-4d1c-b96c-3adef5eb46ad-1feb/" frameborder="0" allowfullscreen></iframe>
            <iframe width="428" height="713" src="https://xd.adobe.com/embed/c67318dd-b75f-4074-832b-4a9eef46ea58-9320/" frameborder="0" allowfullscreen></iframe>
            </div>*/}
            </div>
           <div className='col-12 mb-3'>
            <div className='row justify-content-around  mx-1'>
                <div className='col-md-4 col-12 text-center text-md-end'>
                    <img className='img-fluid shadower'  src={four} />
                </div>
                <div className='col-md-4 col-12 text-center'>
                <img className='img-fluid arr mt-md-4 mt-lg-4'  src={myarr} />
                </div>
                <div className='col-md-4 col-12 text-center text-md-start'>
                    <img className='img-fluid shadower'  src={five} />
                </div>
                
                
                
            
                </div>
           </div>
           <p class="mt-5 pt-4"></p>
           <div className='text-center mt-md-5 pt=md-5 mb-4' ><a href='https://itsure.io/archive/KWESO/index.html' className='btn btn-doge text-white m-md-5 m-2 p-1'>Kweso Website</a><a className='p-1 btn btn-doge text-white m-md-5 m-2' href="https://itsure.io/archive/everCharter/index.html">School Website</a></div>
            <div className='pt-3'>
              <h4 className='p-0 pb-2 pt-5 m-0 lead'>Other Apps - Snapshot</h4>
              <img src={thingthree} className='img-fluid' />
            </div>
        </>
    )
  };
  
  export default Projects;