import landing from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/landing-wire.png'
import apz from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/final-b4-code-app.png'
import apz1 from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/help-request.png'
import apz2 from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/style-guide-chip.png'
import apz3 from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/mobile-register.png'
import apz4 from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/side-nav-landing.png'
import apz5 from 'C:/Users/justs/Desktop/react-portfolio/portfolio/src/assests/budget-color-change.png'
const ProjectChip = () => {
    return(
        <>
         <div className='row'>
        <div className='bggB d-none d-xl-flex col-xl-2'></div>
        

        <div className='col-12 col-md-9 mb-3 col-xl-8'>
        <div className='row'><div className='col-10 col-md-8'><div className='card rounded-0 p-2 mt-3'>
            <div className=''>
                <h4>Information</h4>
                <p className='p-0 m-0'>Author: Jason Ure</p>
                <p className='p-0 m-0'>Showcasing: User Experience Design Project</p>
                <p className='p-0 m-0'>Time: Months</p>
                <p className='p-0 m-0'>Contributers: Jason U., Bret N., Shelby W.</p>
            </div>
            </div></div><div className='col-md col-2 w-50'><p className=' bg-primary text-white bth my-3 w-100 rounded-0 d-md-inline-block rotate'>Chippen App</p></div></div>
        <div className='mt-3 mt-md-0 col-7'></div>

        <h3 className="display-3 ">Chipen App - Introduction</h3>
        <p class="lead l ">
        Come along for the ride in this amazing banking application that me and some of my colleagues made. We created, planned, and built a full stack application.
        The Chipin App was completed working front to back. Working with multiple frameworks, designs, content, research, and deployment
        </p>
        <div className="footer-tag p-2">
         <span className="m-3 p-3">Later after the app was done the little piggie said, "Aint no wolf blowing this App down!</span>
        </div>
        <h3 className="display-3">Overview</h3>
        <p class="lead l">
        Our purpose of the Chipin App was to launch and deploy a full stack budgeting application. 
        During our planning phase we researched using competitor analysis to compare the competition.
         We looked at many mobile and desktop budgeting applications with there design as well made sure
          they were ranked among the best and had good recommendations. After that we went to work on planning
           out what we had to do every week of the semester and wrote and mapped out what we needed to accomplish
            and have done by date. Little did we know we took upon ourselves some additions that would take us
             longer than we expected. Even though it seemed to be cringing on time, we were able to get through
              some of the tuff challenges and complete the project in a timely manner. 
              
        </p>
        <h3 className="display-3">Planning</h3>
        <p class="lead l">
        During our planning phase we discussed how we were going to code the project front to end and talked about
         having more of a custom deployment and making things be automated with changes we made. The use of git
          repository and docker system is what we went with to help each one of us see and make updates when 
          needed. We used 2 applications. One being the landing and the other being the application itself. The
           landing was coded using the React framework and the Main app was coded using the Angular framework. 
           Not to get into to much more detail I will also mention we used Express.Js with REST.
        </p>
        <div className='row'>
            <div className='col-12 col-md-6'>
        <img src={landing} className='img-fluid w-100'/>
            </div>
            <div className='col-12 col-md-6'>
        <img src={apz} className='img-fluid w-100'/>
            </div>
            </div>
        <h3 className="display-3">App Design</h3>
        <p class="lead l">
        After we researched other budgeting applications we came up with the name Chipin and decided 
        and came up with a logo. 
        In the mist of this we were all thinking about sketches and coming up with them. I was particularly
        thinking about Accessablility and was in the works of looking at some accessibility testing and configuring 
        so we could be inline with that. 
        </p>
        <p className="lead l">
        The reason for this was because some of the Banking or Budgeting applications I’ve seen and used made 
        text not very readable. I also researched what fonts we could use to better make reading of $$$ fields 
        and to make sure its one that will line up line after line exactly. I mentioned Lato worked well and we 
        started using that font. We also decided on sizes and color during this time. Later we eventually were 
        able to come to a decision on making a desktop web budgeting calendar application for our users.   
        </p>
        <div className='col-12'>
        <img src={apz2} className='img-fluid w-100'/>
            </div>
            <div class="card mb-3 rounded-0">
                <div class="row g-0">
                    <div class="col-md-4">
                    
                    <img src={apz4} className='img-fluid w-100' alt="side-nav"/>
                        
                    </div>
                    <div class="col-md-8">
                    <div class="card-body rounded-0">
                    
                        <p className="lead l">
                        After that we cut out some of our ideas and crafted the design of our application to fit what we agreed
                        upon. Once are ideas for the design were finalised we started to work on the application. Through out 
                        coding that the application we made some small re-design choices to make our application look more up 
                        to date. 
                        </p>
                        <p className="lead l">
                        Our design was heavily focused around the research we used as Ideas and validated good design from bad 
                        based on reviews and design principles. We did get feedback throughout and that helped.
                        </p>
                        
                    </div>
                    </div>
                </div>
                </div>
        
        <div className='row justify-content-around psb px-2 py-5 text-center m-0'>
            <div className='col-md-4 ps'>
        <img src={apz1} className='img-fluid psi'/>
            </div>
            
            <div className='col-md-4'>
        <img src={apz3} className='img-fluid psi'/>
            </div>

            </div>
                <div className=' col-12'>
                <img src={apz5} className='img-fluid w-100' alt="side-nav"/>
                </div>
        </div>
        <div className='bggA d-none d-md-block col-12 col-xl-2 col-md-3'>
           
        </div>
        </div>
        
        
        </>
    )
}

export default ProjectChip